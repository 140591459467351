<template>
    <b-container fluid="xl szechenyi">
        <b-row>
            <b-col>
                <p>a) a Kedvezményezett neve: <b>NT ÉLELMISZERTERMELO ÉS KERESKEDELMI Kft.</b></p><br>
                <p>b) felhívás neve, kódszáma: <b>Élelmiszeripari üzemek komplex fejlesztése,  VP3-4.2.1-4.2.2.-2-21</b></p><br>
                <p>c) a projekt címe, azonosító száma: <b>Kapacitás növelő technológiai fejlesztés az NT Kft-nél, 3309528477</b></p><br>
                <p>d) a szerződött támogatás összege:<b>1 556 894 337 Ft</b></p><br>
                <p>e) a támogatás mértéke (%-ban): <b>50,00 %</b></p><br>
                <p>f) a projekt tartalmának rövid bemutatása: <br><br>
                <b>Az NT Kft. kapacitásbővítését a 2017 évben határoztuk el. 2019-2021 között 12 milliárdos fejlesztés keretében a napraforgómag présüzem kapacitását dupláztuk meg. A megnövekedett kapacitás adta további fejlesztésünk alapját, amelyben finomító, tároló kapacitásunkat és palackozó üzemünket bővítjük. A megnövekedett nyersolaj előállítás szükségessé teszi a finomító, tároló, és palackozó kapacitásunk bővítését.
                Ez a bővítés a napi kapacitás 35%-os emelését teszi lehetővé. Jelenleg kémiai úton zajlik a finomítás, a projekt tárgyát képező berendezés ezt fizikai módon végezné. A fizikai finomítás egyben környezetkímélőbb eljárás is. Megújuló energiatermelési kapacitásunkat egy új maghéj tüzelésű gőzkazán üzembe állításával növeljük, ezzel csökkentve cégünk ökológiai lábnyomát.</b></p><br>
                <p class="mb-5">g) a projekt tervezett befejezési dátuma: <b>2023.09.30.</b></p>
            </b-col> 
        </b-row>
    </b-container>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 1);
    }
}
</script>